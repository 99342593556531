body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(135,185,224);
  background: -moz-linear-gradient(0deg, rgba(135,185,224,1) 0%, rgba(5,30,74,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(135,185,224,1) 0%, rgba(5,30,74,1) 100%);
  background: linear-gradient(15deg, rgba(135,185,224,1) 0%, rgba(5,30,74,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#87b9e0",endColorstr="#051e4a",GradientType=1);
  min-width: 100vw;
  min-height: 100vh;
}